<template>
  <div class="layout-topbar">
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <Dropdown
      v-if="!loading"
      v-model="store.consignatariaSelecionada"
      class="ml-3 layout-topbar-consignataria-dropdown"
      :options="store.consignatarias"
      optionLabel="razaoSocial"
      placeholder="Selecione a consignatária" />
    <Skeleton v-if="loading" class="ml-2" width="50%" />

    <span class="layout-topbar-user-name"> {{ $auth.user.nomecompleto }} </span>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="toggleMenu">
      <i class="pi pi-user"></i>
      <span>Usuário</span>
    </button>
    <Menu ref="profilemenu" :model="items" :popup="true" />
  </div>
</template>

<script>
import { watch } from 'vue-demi'
import Config from '@/config'
import ConsignatariaService from '@/service/ConsignatariaService'
import { consignatariaStore } from '@/stores/consignataria'

export default {
  setup() {
    const store = consignatariaStore()
    return { store }
  },

  data() {
    return {
      loading: false,
      items: [],
    }
  },

  created() {
    this.preencheItens()
    this.loading = true
    this.service = new ConsignatariaService(this.$http)
    this.service
      .getConsignatariaLogada()
      .then((res) => {
        this.loading = false
        this.store.$reset()
        this.store.consignatarias = res
        if (!this.store.consignatariaSelecionada) {
          if (res.length === 1) {
            this.store.consignatariaSelecionada = res[0]
            localStorage.setItem(
              'consignatariaSelecionada',
              JSON.stringify(res[0]),
            )
            if (this.store.consignatariaSelecionada.suspensaoEntidades.length) {
              this.avisaSuspensao(
                this.store.consignatariaSelecionada.suspensaoEntidades,
              )
            }
          }
        }

        if (localStorage.getItem('consignatariaSelecionada')) {
          this.store.consignatariaSelecionada = JSON.parse(
            localStorage.getItem('consignatariaSelecionada'),
          )
        } else {
          if (
            this.$route.name !== 'servidores' &&
            this.$route.name !== 'usuarios'
          ) {
            this.$router.replace({ name: 'servidores' })
          }
        }

        watch(
          () => this.store.consignatariaSelecionada,
          (state) => {
            const saved = localStorage.getItem('consignatariaSelecionada')
            if (saved !== JSON.stringify(state)) {
              if (
                this.$route.name !== 'servidores' &&
                this.$route.name !== 'usuarios'
              ) {
                this.$router.replace({ name: 'servidores' })
              }
            }

            localStorage.setItem(
              'consignatariaSelecionada',
              JSON.stringify(state),
            )
            if (this.store.consignatariaSelecionada.suspensaoEntidades.length) {
              this.avisaSuspensao(
                this.store.consignatariaSelecionada.suspensaoEntidades,
              )
            }
          },
          { deep: true },
        )
      })
      .catch((err) => {
        this.loading = false
        const mensagem = err.response
          ? err.response.data.message
          : 'Erro de conexão com a aplicação.'
        this.$toast.add({
          severity: 'error',
          summary: mensagem,
          life: 10000,
        })
        this.$router.push('/logout')
      })
  },

  methods: {
    preencheItens() {
      const itemsBase = [
        {
          label: this.$auth.user.nomecompleto,
          disabled: true,
          class: 'menu-item-responsive',
        },
        {
          separator: true,
          class: 'menu-item-responsive',
        },
        {
          label: 'Sair',
          icon: 'pi pi-sign-out',
          command: () => {
            this.$router.push('/logout')
          },
        },
      ]
      if (Config.HABILITA_BOTAO_2FA) {
        const item = {
          label: 'Autenticação de dois fatores',
          command: () => {
            window.location.href =
              Config.REALM_PB +
              '/protocol/openid-connect/auth?client_id=' +
              Config.CLIENT_ID +
              '&redirect_uri=' +
              window.location.origin +
              window.location.pathname +
              '&response_type=code&scope=openid&kc_action=CONFIGURE_TOTP'
          },
        }
        itemsBase.splice(1, 0, item)
      }
      this.items = itemsBase
    },

    toggleMenu(event) {
      this.$refs.profilemenu.toggle(event)
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event)
    },
    avisaSuspensao(suspensoes) {
      const nomes = suspensoes.map((entidade) => entidade.nome)
      const nomesString = nomes.join(', ')
      this.$toast.add({
        severity: 'error',
        summary: `A consignataria esta suspensa com as Entidades: ${nomesString}. Regularize o contrato o quanto antes!`,
        life: 10000,
      })
    },
  },
}
</script>
